export const QUERY = {
  user: 'user',
  messages: 'messages',
  singleMessage: 'single-message',
  badges: 'badges',
  infiniteBadges: 'infinite-badges',
  badgeItem: 'badgeItem',
  globalLeaderboard: 'global-leaderboard',
  leaderboards: 'leaderboards',
  leaderboardFans: 'leaderboards-fans',
  singleLeaderboard: 'single-leaderboard',
  groups: 'groups',
  conversations: 'conversations',
  communityConversations: 'community-conversations',
  singleConversation: 'single-conversation',
  userSuggestions: 'user-suggestions',
  teams: 'teams',
  collaborators: 'collaborators',
  stores: 'stores',
  storeDetails: 'storeDetails',
};
