export const ROUTES = {
  root: '/',
  auth: '/auth',
  dashboard: {
    root: '/dashboard',
    messages: '/dashboard/messages',
    promotions: '/dashboard/promotions',
    badges: '/dashboard/badges',
    badgeItem: '/dashboard/badges/badge',
    customise: '/dashboard/customise',
    settings: '/dashboard/settings',
    managePlans: '/dashboard/manage-plans',
    community: '/dashboard/community',
    post: '/dashboard/community/post',
    comment: '/dashboard/community/post/comment',
    reply: '/dashboard/community/comment/reply',
    challenges: '/dashboard/challenges',
    createChallenge: '/dashboard/challenges/create-challenge?name=Create%20Challenge',
    updateChallenge: '/dashboard/challenges/create-challenge',
    viewChallenge: '/dashboard/challenges/challenge',
    contents: '/dashboard/contents',
  },
  collaboration: {
    badge: '/collaboration',
  },
  create: {
    message: '/create-message',
    badge: '/create-badge',
  },
  fanProfile: '/fan-profile',
};
