import { getCookie, setCookie, deleteCookie } from 'cookies-next';
import CryptoJS from 'crypto-js';
const ENCRYPTION_KEY = process.env.NEXT_PUBLIC_AUTO_COOKIES_ENCRYPTION_KEY;

export interface IAuthResponse {
  accessToken: string;
  refreshToken: string;
  userId: string;
}

export const COOKIES_MAP = {
  accessToken: 'KOR_LINK-accessToken',
  refreshToken: 'KOR_LINK-refreshToken',
  userId: 'KOR_LINK-userId',
};

export const cookieService = {
  setAuthCookies({ accessToken, refreshToken, userId }: IAuthResponse) {
    setCookie(COOKIES_MAP.accessToken, accessToken);
    setCookie(COOKIES_MAP.refreshToken, refreshToken);
    setCookie(COOKIES_MAP.userId, userId);
  },
  clearAuthCookies() {
    deleteCookie(COOKIES_MAP.accessToken);
    deleteCookie(COOKIES_MAP.refreshToken);
    deleteCookie(COOKIES_MAP.userId);
  },
  getAuthCookies() {
    return {
      accessToken: getCookie(COOKIES_MAP.accessToken) || null,
      refreshToken: (getCookie(COOKIES_MAP.refreshToken) as string) || null,
      userId: getCookie(COOKIES_MAP.userId) || null,
    };
  },
  isAuthed() {
    const data = this.getAuthCookies();
    const isAuth = !Object.values(data).some((el) => el === null);
    return isAuth;
  },
  getUserId() {
    return getCookie(COOKIES_MAP.userId);
  },
  encryptAuthData(data: IAuthResponse) {
    try {
      const dataToEncrypt = {
        ...data,
        timestamp: Date.now(),
      };
      const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify(dataToEncrypt),
        ENCRYPTION_KEY,
      ).toString();
      return encodeURIComponent(encrypted);
    } catch (error) {
      console.error('Encryption error:', error);
      throw new Error('Failed to encrypt auth data');
    }
  },
  getEncryptedAuthData(extraData?: Record<string, string>) {
    const cookieData = this.getAuthCookies();
    if (!this.isAuthed()) {
      throw new Error('User is not authenticated');
    }
    return this.encryptAuthData({
      ...cookieData,
      isCreatorFanAccount: 'true',
      ...extraData,
    } as IAuthResponse);
  },
};
