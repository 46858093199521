'use client';
import { useRouter, useSearchParams } from 'next/navigation';
import { ROUTES } from '@constants/routes.constants';
import { useGetUserQuery } from '@lib/redux/api/user-api';
import { useEffect } from 'react';
import { useProcessOAuthMutation } from '@lib/redux/api/e-commerce-api';

export default function RootPage() {
  const searchParams = useSearchParams();

  const shopifyAuthCode = searchParams.get('code');
  const shopifyShopDomain = searchParams.get('shop');

  const [processOAuth] = useProcessOAuthMutation();

  const { data, isSuccess, isLoading } = useGetUserQuery();

  const router = useRouter();

  useEffect(() => {
    router.prefetch(ROUTES.dashboard.root);
    router.prefetch(ROUTES.auth);
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isSuccess && data) {
      router.push(ROUTES.dashboard.root);
    } else {
      if (shopifyAuthCode && shopifyShopDomain) {
        // trigger shopify oauth processing
        processOAuth({ authorization_code: shopifyAuthCode, shop_domain: shopifyShopDomain })
          .unwrap()
          .then((res) => {
            router.push(
              `${ROUTES.auth}?shopify_shop_id=${res.id}&shopify_email=${res.email}&shopify_name=${res.shop_owner_name}`,
            );
          })
          .catch((error) => {
            // TODO: what should we do
            console.error('Error during OAuth processing:', error);

            router.push(ROUTES.auth);
          });
      } else {
        router.push(ROUTES.auth);
      }
    }
  }, [isSuccess, isLoading, shopifyAuthCode, shopifyShopDomain]);

  return null;
}
