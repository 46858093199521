import { createApi } from '@reduxjs/toolkit/query/react';
import { authQuery } from '../config/query.config';
import { cookieService } from '@lib/cookie.service';
import { QUERY } from '@constants/query.constants';
import { ErrorResponse, OAuthRequest, OAuthResponse, StoreConnectionResponse } from './types';
import {
  CreateMappingRequest,
  CreateMappingResponse,
  FetchProductsListArgs,
  MappingsListResponse,
  ProductsListResponse,
  StoreDetailsRequest,
  StoreDetailsResponse,
  UpdateMappingStatusRequest,
} from 'app/(app)/dashboard/e-commerce/typing';

export const ecommerceApi = createApi({
  reducerPath: 'ecommerce-api',
  baseQuery: authQuery,
  tagTypes: [QUERY.stores, QUERY.storeDetails],
  endpoints: (builder) => ({
    getMappingsList: builder.query<MappingsListResponse, { shop_id: number }>({
      query: ({ shop_id }) => ({
        url: `shopify/shops/${shop_id}/mappings/list`,
        method: 'GET',
      }),
      transformResponse: (response: MappingsListResponse | ErrorResponse) => {
        if ('error' in response) {
          throw new Error(response.error);
        }
        return response;
      },
      providesTags: [QUERY.stores],
    }),
    // Process OAuth Callback
    processOAuth: builder.mutation<OAuthResponse, OAuthRequest>({
      query: (body) => ({
        url: 'shopify/oauth/process_callback',
        method: 'POST',
        body,
      }),
      transformResponse: (response: OAuthResponse | ErrorResponse) => {
        if ('error' in response) {
          throw new Error(response.error);
        }
        return response;
      },
    }),
    // Check Store Connection
    checkStoreConnection: builder.query<StoreConnectionResponse, string>({
      query: (creator_id) => ({
        url: `shopify/shops/retrieve/by_creator_id?creator_id=${creator_id}`,
      }),
      transformResponse: (response: StoreConnectionResponse | ErrorResponse) => {
        if ('error' in response) {
          throw new Error(response.error);
        }
        return response;
      },
      providesTags: [QUERY.stores],
    }),

    fetchProductsList: builder.query<ProductsListResponse, FetchProductsListArgs>({
      query: ({ shop_id, query }) => ({
        url: `shopify/shops/${shop_id}/products/list${query ? `?query=${query}` : ''}`,
        method: 'GET',
      }),
      transformResponse: (response: ProductsListResponse | ErrorResponse) => {
        if ('error' in response) {
          throw new Error(response.error);
        }
        return response;
      },
      providesTags: [QUERY.stores],
    }),

    createMapping: builder.mutation<
      CreateMappingResponse,
      { shop_id: number } & CreateMappingRequest
    >({
      query: ({ shop_id, ...body }) => ({
        url: `shopify/shops/${shop_id}/mappings/create`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateMappingResponse | ErrorResponse) => {
        if ('error' in response) {
          throw new Error(response.error);
        }
        return response;
      },
      invalidatesTags: [QUERY.stores],
    }),

    updateMappingStatus: builder.mutation<{ status: string }, UpdateMappingStatusRequest>({
      query: ({ shop_id, mapping_id, status }) => ({
        url: `shopify/shops/${shop_id}/mappings/${mapping_id}/update_status`,
        method: 'PUT',
        body: { status },
      }),
      async onQueryStarted({ shop_id, mapping_id, status }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          ecommerceApi.util.updateQueryData('getMappingsList', { shop_id }, (draft) => {
            const mapping = draft.find((m) => m.id === mapping_id);
            if (mapping) {
              mapping.status = status;
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [QUERY.stores],
    }),

    triggerProductSync: builder.mutation<{ message: string }, { shop_id: number }>({
      query: ({ shop_id }) => ({
        url: `shopify/shops/${shop_id}/products/sync`,
        method: 'POST',
      }),
      transformResponse: (response: { message: string } | ErrorResponse) => {
        if ('error' in response) {
          throw new Error(response.error);
        }
        return response;
      },
    }),

    // Check Store Connection
    getStoreDetails: builder.query<StoreDetailsResponse, void>({
      query: () => {
        const userId = cookieService.getUserId();
        return {
          url: `/store-details/${userId}`,
        };
      },
      transformResponse: (response: StoreDetailsResponse | ErrorResponse) => {
        if ('error' in response) {
          throw new Error(response.error);
        }
        return response;
      },
      providesTags: [QUERY.storeDetails],
    }),

    createStoreDetails: builder.mutation<StoreDetailsResponse, StoreDetailsRequest>({
      query: (body) => ({
        url: '/store-details/create',
        method: 'POST',
        body,
      }),
      transformResponse: (response: StoreDetailsResponse | ErrorResponse) => {
        if ('error' in response) {
          throw new Error(response.error);
        }
        return response;
      },
      invalidatesTags: [QUERY.storeDetails],
    }),

    updateStoreDetails: builder.mutation<StoreDetailsResponse, StoreDetailsRequest>({
      query: (body) => ({
        url: `/store-details/update`,
        method: 'PATCH',
        body,
      }),
      transformResponse: (response: StoreDetailsResponse | ErrorResponse) => {
        if ('error' in response) {
          throw new Error(response.error);
        }
        return response;
      },
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          ecommerceApi.util.updateQueryData('getStoreDetails', undefined, (draft) => {
            if (draft) {
              draft.store_url = body.store_url || draft.store_url;
              draft.store_logo_url = body.store_logo_url || draft.store_logo_url;
              draft.visibility = body.visibility ?? draft.visibility;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: [QUERY.storeDetails],
    }),
  }),
});

export const {
  useProcessOAuthMutation,
  useCheckStoreConnectionQuery,
  useFetchProductsListQuery,
  useCreateMappingMutation,
  useGetMappingsListQuery,
  useUpdateMappingStatusMutation,
  useTriggerProductSyncMutation,
  useGetStoreDetailsQuery,
  useCreateStoreDetailsMutation,
  useUpdateStoreDetailsMutation,
} = ecommerceApi;
